import request from "@/api/server/index"

/**
 * 分页查询设备分类列表
 * method: post
 * url: /manager/deviceGroup/page
 * param page         Number    当前页码
 * param pageSize     Number    每页条数
 * param groupName    string    设备分组名称
 * param sortItems    Object    排序字段列表 ,示例值([ { "column": "age", "asc": false } ])
 * sortItems asc      boolean   排序方向：true升序，fase降序 ,示例值(true)
 * sortItems column   String    需要进行排序的字段 ,示例值(createBy)
*/
export function getdeviceGroupList(data) {
    return request({
        url: '/manager/deviceGroup/page',
        method: 'post',
        data
    })
}

/**
 * 保存分组
 * method: post
 * url: /manager/deviceGroup/save
 * param    groupName   string  分组名称
 * param    remark      string  分组描述
*/
export function deviceGroupSave(data) {
    return request({
        url: '/manager/deviceGroup/save',
        method: 'post',
        data
    })
}

/**
 * 根据设备ID移除出分组
 * method: post
 * url: /manager/deviceGroup/removeGroup
 * param    deviceIds   array  deviceIds
*/
export function deviceGroupRemove(data) {
    return request({
        url: '/manager/deviceGroup/removeGroup',
        method: 'post',
        data
    })
}

/**
 * 根据多个ID批量逻辑删除
 * method: post
 * url: /manager/deviceGroup/batchRemoveById
 * param    ids   array  ids
*/
export function deviceGroupBatchRemoveById(data) {
    return request({
        url: '/manager/deviceGroup/batchRemoveById',
        method: 'post',
        data
    })
}

/*------------分组详情相关接口----------------*/
/**
 * 分页查询设备列表
 * method: post
 * url: /manager/device/page
 * param    groupId     number      分组id
 * param    deviceName  string      设备名称
 * param    deviceCode  string      设备code
 * param    page        number      当前页码 ,示例值(1)
 * param    pageSize    number      每页记录数 ,示例值(1)
 * param    productName string      所属产品名称
 * param    productCode string      所属产品code
 */
export function getDeviceData(data) {
    return request({
        url: '/manager/device/page',
        method: 'post',
        data
    })
}
/**
 * 添加设备进分组
 * method: post
 * url: /manager/deviceGroup/addGroup/{groupId}
 * param    deviceIds   array   
 * param    groupId     path
 */
export function addDeviceToGroup(data,groupId) {
    return request({
        url: `/manager/deviceGroup/addGroup/${groupId}`,
        method: 'post',
        data
    })
}